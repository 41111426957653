import React from 'react';
import './AboutUs.css';  // We'll style the page in this CSS file

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <section className="about-header">
        <h1>Welcome to PortPoint Engineering</h1>
        <p>Your trusted partner in engineering solutions</p>
      </section>

      <section className="about-section">
        <h2>Who We Are</h2>
        <p>
          PortPoint Engineering is a leading provider of cutting-edge engineering solutions,
          specializing in maritime, port infrastructure, and industrial engineering. Our expertise
          in the design, development, and execution of complex projects allows us to offer unparalleled
          services to our clients.
        </p>
      </section>

      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>
          At PortPoint Engineering, we aim to deliver innovative, sustainable, and high-quality
          engineering solutions that help improve operational efficiency, ensure safety, and enhance
          performance for our clients worldwide. We are committed to being a forward-thinking partner,
          addressing challenges with precision, and delivering results.
        </p>
      </section>

      <section className="values-section">
        <h2>Our Core Values</h2>
        <ul>
          <li><strong>Innovation:</strong> We prioritize creative, cutting-edge solutions for every project.</li>
          <li><strong>Integrity:</strong> We operate with honesty, transparency, and a commitment to the highest ethical standards.</li>
          <li><strong>Excellence:</strong> We strive for excellence in all our services and solutions.</li>
          <li><strong>Sustainability:</strong> We focus on providing solutions that are environmentally sustainable and socially responsible.</li>
        </ul>
      </section>
    </div>
  );
};

export default AboutUs;
