// Import Swiper React components
import '../style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import bgImage from '../images/portPoint.jpeg';
import bgImage1 from '../images/portPoint2.jpg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Parallax, Navigation } from 'swiper/modules';
import { height } from '@mui/system';

export default () => {
    var imageUrl = [bgImage,bgImage1];
  return (
    <Swiper
    style={{
      '--swiper-navigation-color': '#fff',
      height:400
    }}
    speed={600}
    parallax={true}
    
    navigation={true}
    modules={[Parallax, Navigation]}
    className="mySwiper"
  >
    <div
      slot="container-start"
      className="parallax-bg"
      style={{
        'backgroundImage':`url(${imageUrl[0]})`,
      }}
      data-swiper-parallax="-23%"
    ></div>
    <SwiperSlide >
      <div className="title" data-swiper-parallax="-300">
      Port Point 
      </div>
      <div className="subtitle" data-swiper-parallax="-200">
      Engineering
      </div>
      <div className="text" data-swiper-parallax="-100">
        <p>
        29 years of committment to being a reference POINT in turning engineering, procurement, construction
        and installation concepts into reality.
        </p>
      </div>
      <div class="group group-md"> <a class="button button-sm button-primary-filled veil reveal-sm-inline-block" href="services">We can help</a> </div>
    </SwiperSlide>
    {/* <SwiperSlide>
      <div className="title" data-swiper-parallax="-300">
        Slide 2
      </div>
      <div className="subtitle" data-swiper-parallax="-200">
        Subtitle
      </div>
      <div className="text" data-swiper-parallax="-100">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla
          laoreet justo vitae porttitor porttitor. Suspendisse in sem justo.
          Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod.
          Aliquam hendrerit lorem at elit facilisis rutrum. Ut at
          ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec,
          tincidunt ut libero. Aenean feugiat non eros quis feugiat.
        </p>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="title" data-swiper-parallax="-300">
        Slide 3
      </div>
      <div className="subtitle" data-swiper-parallax="-200">
        Subtitle
      </div>
      <div className="text" data-swiper-parallax="-100">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla
          laoreet justo vitae porttitor porttitor. Suspendisse in sem justo.
          Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod.
          Aliquam hendrerit lorem at elit facilisis rutrum. Ut at
          ullamcorper velit. Nulla ligula nisi, imperdiet ut lacinia nec,
          tincidunt ut libero. Aenean feugiat non eros quis feugiat.
        </p>
      </div>
    </SwiperSlide> */}
  </Swiper>
  );
};