import React from 'react';
import { AppBar, Toolbar, Typography ,Container,Grid2, Stack} from '@mui/material';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import Logo from '../images/logo.png'

const HeaderTop = () => {
  return (
    <AppBar position="static" style={{ backgroundColor: '#e5e0dd',height: '100px' }}>
      <Container>
        <Toolbar>
        {/* <img
              src={Logo} 
              alt="Logo"
              style={{ height: '50px', textAlign:'left'}} // Adjust size as needed
            /> */}
        <Stack direction="row" spacing={10} padding={2}>
       
          <Grid2 container alignItems="center" spacing={2}>
            {/* Image on the left */}
            <Grid2 item>
               <AccessAlarmsIcon  color="primary" fontSize="large" />
              </Grid2>
            {/* Text content */}
            <Grid2 item>
              <Grid2 container direction="column">
                <Typography variant="h6" style={{ color: 'black' }}>
                  Sun - Thus: 7:30 - 16:30
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'black' }}>
                  Friday & Saturday CLOSED
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center" spacing={2}>
            {/* Image on the left */}
            <Grid2 item>
               <PlaceIcon color="primary" fontSize="large"/>
              </Grid2>
            {/* Text content */}
            <Grid2 item>
              <Grid2 container direction="column">
                <Typography variant="h6" style={{ color: 'black' }} >
               72 College Road,Chawkbazar
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'black' }}>
                Chattogram,Bangladesh
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 container alignItems="center" spacing={2}>
            {/* Image on the left */}
            <Grid2 item>
               <CallIcon color="primary" fontSize="large"/>
              </Grid2>
            {/* Text content */}
            <Grid2 item>
              <Grid2 container direction="column">
                <Typography variant="h6" style={{ color: 'black' }} >
                (+88) 01711-908277
                </Typography>
                <Typography variant="subtitle1" style={{ color: 'black' }}>
                portpointengineering@gmail.com
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
          </Stack>
        </Toolbar>
        </Container>
     
    </AppBar>
  );
};

export default HeaderTop;
