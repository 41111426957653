import React from 'react';
import './Footer.css'; // For styling the footer

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Company Information */}
        <div className="footer-section company-info">
          <h2>PortPoint Engineering</h2>
          <p>
            Leading provider of innovative and sustainable engineering solutions for maritime, industrial, and infrastructure projects.
          </p>
        </div>

        {/* Navigation Links */}
        <div className="footer-section navigation">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="./Services">Services</a></li>
            <li><a href="/projects">Projects</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="footer-section social-media">
          <h3>Follow Us</h3>
          <ul>
            <li><a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://instagram.com/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
      </div>

      {/* Copyright Notice */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} PortPoint Engineering. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
