import React from 'react';
import './Services.css'; // Styling for the services page

const Services = () => {
  return (
    <div className="services-container">
      {/* Header Section */}
      <header className="services-header">
        <h1>Our Engineering Services</h1>
        <p>Innovative and Sustainable Engineering Solutions for Your Business</p>
      </header>

      {/* Services Overview */}
      <section className="services-overview">
        <h2>What We Offer</h2>
        <p>
          At PortPoint Engineering, we provide a wide range of specialized engineering services.
          Whether you're looking to enhance your port infrastructure, improve industrial processes, or
          develop sustainable solutions, our team has the expertise to turn your vision into reality.
        </p>
      </section>

      {/* List of Services */}
      <section className="services-list">
        <h2>Our Services</h2>
        <div className="service-items">
          <div className="service-item">
            <h3>Maritime Engineering</h3>
            <p>
              We specialize in maritime infrastructure, including port construction, dredging, and the design of
              marine structures. Our team is equipped to handle projects that require deep technical knowledge and
              innovation in the maritime industry.
            </p>
          </div>
          <div className="service-item">
            <h3>Industrial Solutions</h3>
            <p>
              From manufacturing optimization to automation, we provide tailored industrial engineering solutions
              that help improve operational efficiency and reduce costs. Our team helps industries scale while
              ensuring high-quality production standards.
            </p>
          </div>
          <div className="service-item">
            <h3>Infrastructure Development</h3>
            <p>
              We deliver comprehensive infrastructure solutions, from transportation systems to urban planning,
              focusing on building sustainable, cost-effective, and resilient infrastructures that stand the test of time.
            </p>
          </div>
          <div className="service-item">
            <h3>Sustainability Consulting</h3>
            <p>
              Our sustainability consultants assist businesses in reducing their environmental impact through
              green engineering practices. We help implement solutions that reduce energy consumption, waste,
              and carbon footprints.
            </p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose PortPoint Engineering?</h2>
        <ul>
          <li><strong>Expertise:</strong> Over 20 years of experience in engineering solutions across multiple sectors.</li>
          <li><strong>Innovation:</strong> We use the latest technologies and methodologies to deliver the best results.</li>
          <li><strong>Sustainability:</strong> We ensure that all our solutions are environmentally sustainable and future-proof.</li>
          <li><strong>Quality Assurance:</strong> We are committed to delivering the highest standards of engineering excellence.</li>
        </ul>
      </section>
    
    </div>
  );
};

export default Services;
